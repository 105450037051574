import React from "react";
import "components/style.css";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { Link } from "react-router-dom";
import { postFetch } from "lib/fetch";
import { useState } from "react";
import { blueGradient1 } from "components/tailwindClasses";

import {
  FormControl,
  FormControlLabel,
  Typography,
  // RadioGroup,
  // Radio,
  // List,
  // ListItem,
} from "@mui/material";

import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import { Sheet } from "@mui/joy";

function Login() {
  let navigate = useNavigate();

  // state for radio buttons
  const [company, setCompany] = useState("normal");
  const [role, setRole] = useState("manager");

  const companyOptions = { security: "CyeNET", normal: "Customer Company" };
  const roleOptions = {
    manager: {
      label: "Manager",
      logo:
        role == "manager"
          ? "/managerLoginLogo2Selected.svg"
          : "/managerLoginLogo2.svg",
    },
    engineer: {
      label: "Engineer",
      logo:
        role == "engineer"
          ? "/engineerLoginLogo2Selected.svg"
          : "/engineerLoginLogo2.svg",
    },
  };

  const handleNextClick = () => {
    const getForm = document.getElementById("loginStartPage02nr2d");

    if (getForm && getForm?.checkValidity()) {
      if (company == "security" && role == "engineer") {
        navigate("/login/SecurityEngineer");
      } else if (company == "security" && role == "manager") {
        navigate("/login/SecurityManager");
      } else if (company == "normal" && role == "engineer") {
        navigate("/login/CompanyEngineer");
      } else if (company == "normal" && role == "manager") {
        navigate("/login/CompanyManager");
      }
    } else {
      getForm?.reportValidity();
    }
  };

  return (
    <div
      className={
        "relative h-full flex justify-center items-center " + blueGradient1
      }
    >
      <img
        src={"/loginBgLines.svg"}
        className="  absolute top-0 left-0 w-1/5  -scale-y-100"
        alt="anim"
      />
      <div className="  absolute bottom-0 left-0 flex items-end ml-8 mb-4">
        <img src="/loginGirl.svg" alt="" className="mr-8" />
        <img src="/loginLaptop.svg" alt="" />
      </div>

      <img
        src="/loginMan.svg"
        alt=""
        className="  absolute bottom-0 right-0 mr-8 mb-4"
      />

      <div className="bg-white text-black min-w-[33%] rounded-10 p-1p">
        <form action="" id="loginStartPage02nr2d">
          <div className="text-lg font-medium">
            <img src={"/cyteHackLogo.svg"} className=" h-5 inline mr-2" />
            CyeNET
          </div>

          <div className="mt-6">
            <div className="text-lg font-bold">Select the company</div>
            <div className="mt-1">
              <RadioGroup>
                <List
                  sx={{
                    minWidth: 240,
                    "--ListItem-paddingY": "1rem",
                    "--ListItem-radius": "8px",
                    "--ListItemDecorator-size": "32px",

                    gap: 2,

                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {Object.keys(companyOptions).map((item, index) => (
                    <ListItem
                      variant="outlined"
                      key={item}
                      sx={{
                        boxShadow: "sm",
                        // width: "50%",
                        height: "60px",
                        flexGrow: 1,
                      }}
                    >
                      <Radio
                        overlay
                        required
                        value={item}
                        label={companyOptions[item]}
                        sx={{
                          fontWeight: "500",
                          color: company == item && "rgb(69, 104, 236)",
                        }}
                        onChange={(e) => setCompany(e.target.value)}
                        checked={company == item}
                        slotProps={{
                          action: ({ checked }) => ({
                            sx: (theme) => ({
                              ...(checked && {
                                inset: -1,
                                border: "1px solid #4568EC",
                                bgcolor: "rgba(69, 104, 236, 0.1)",
                              }),
                            }),
                          }),
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </RadioGroup>

              <div className="text-lg font-bold mt-4">
                Are you a manager or engineer?
              </div>

              <FormControl sx={{ width: "100%", marginTop: "12px" }}>
                <RadioGroup
                  overlay
                  name="member"
                  defaultValue="person1"
                  orientation="horizontal"
                  sx={{ gap: 2 }}
                >
                  {Object.keys(roleOptions).map((item, index) => (
                    <Sheet
                      component="label"
                      key={index}
                      variant="outlined"
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        boxShadow: "sm",
                        borderRadius: "md",
                        width: "33%",
                      }}
                    >
                      <Radio
                        overlay
                        // disableIcon
                        required
                        value={item}
                        variant="soft"
                        sx={{
                          mb: 2,
                        }}
                        checked={role == item}
                        onChange={(e) => setRole(e.target.value)}
                        slotProps={{
                          action: ({ checked }) => ({
                            sx: (theme) => ({
                              ...(checked && {
                                inset: -1,
                                border: "1px solid #4568EC",
                                bgcolor: "rgba(69, 104, 236, 0.1)",
                              }),
                            }),
                          }),
                        }}
                      />
                      <img
                        src={roleOptions[item].logo}
                        alt=""
                        className="w-12 h-12"
                      />
                      <Typography
                        level="body-sm"
                        sx={{
                          mt: 1,
                          color: role == item && "rgb(69, 104, 236)",
                        }}
                      >
                        {roleOptions[item].label}
                      </Typography>
                    </Sheet>
                  ))}
                </RadioGroup>
              </FormControl>

              <div
                className={
                  blueGradient1 +
                  " w-full h-15 rounded-10 flex items-center justify-center font-bold mt-5 cursor-pointer"
                }
                onClick={handleNextClick}
              >
                Next
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
